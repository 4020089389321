import { AxiosError, AxiosResponse } from 'axios';

import { fetchData, postData, updateData } from '@/configs/axios';
import { AgentInfoType } from '@/types/Agent';
import { CheckPointType } from '@/types/Checkpoint';
import { AgentActivity } from '@/types/interfaces/agent-activity';
import {
  Bucket,
  Defect,
  DefectRequest,
  Dimension,
  SubmittedDefect,
} from '@/types/interfaces/defect-details';
import { ProductComment } from '@/types/ProductInfo';
import {
  QcInfoType,
  QcListQueryParams,
  QcListType,
  UpdateQcState,
} from '@/types/QcInfo';

import { QcStatesType } from '@/types/QcStatus';
import { StandbyReasonType } from '@/types/StandbyReasons';
import { WarehouseType } from '@/types/Warehouse';
import { getMemberData, getTotalItems } from './hydra.service';
import { AdditionalItemType } from '../types/AdditionalItem';
import { IHydra } from '../types/interfaces/hydra';

export type UploadImageType = 'defect' | 'public' | 'internal';

export interface IError extends Error {
  config?: any;
  data?: any;
  headers: { [header: string]: string | string[] | undefined };
  request: XMLHttpRequest;
  status: number;
  statusText?: string;
}

export interface IPutState {
  agentUsername?: string;
  productId?: number;
  status?: number;
  transitionDone: boolean;
}

export async function scanProduct(
  productId: string,
  isHomePage: boolean
): Promise<QcInfoType[]> {
  let path = `/quality_controls?product.id=${productId}`;

  if (isHomePage) {
    path = `${path}&action=scan`;
  }

  const apiRes: AxiosResponse = await fetchData(path, 'v2');
  const data = getMemberData(apiRes?.data as IHydra);

  return data;
}

export async function getAdditionalItems(): Promise<AdditionalItemType[]> {
  const path = `/additional_items?order[name]=alpha`;

  const apiRes = await fetchData(path, 'v1');
  const data = getMemberData(apiRes?.data as IHydra);

  return data;
}

export async function putQcState({
  uuid,
  state,
  standbyReasonUuid,
  record,
  standbyComment,
}: UpdateQcState): Promise<any> {
  const path = `/quality_controls/${uuid}`;

  const apiRes = await updateData(path, 'v2', {
    transition: state,
    standbyReasonUuid,
    record,
    standbyComment,
  });

  return { data: apiRes.data };
}

export async function getAgent(
  email: string,
  productivityOnly = false
): Promise<AgentInfoType> {
  if (!email) {
    const error = new Error(`No mail specified for get agent.`) as IError;
    error.status = 500;
    throw error;
  }
  const path = '/agents';

  try {
    const apiRes = await fetchData(path, 'v1', { email });
    const data = getMemberData(apiRes?.data as IHydra);
    if (data.length <= 0) {
      const error = new Error(`No agent found with email: ${email}`) as IError;
      error.status = 404;
      throw error;
    }
    if (productivityOnly) {
      return data[0].productivity;
    }
    // Always return the first item since this is the most recent.
    return data[0];
  } catch (error) {
    throw (error as AxiosError).response
      ? (error as AxiosError).response
      : error;
  }
}

export async function getStandbyReasons(): Promise<StandbyReasonType[]> {
  const path = `/standby_reasons?type=quality_control&version=2`;

  const apiRes = await fetchData(path, 'v1');
  return getMemberData(apiRes?.data as IHydra);
}

export async function getWarehouses(): Promise<WarehouseType[]> {
  const path = `/warehouses`;

  const apiRes = await fetchData(path, 'v1');
  return getMemberData(apiRes?.data as IHydra);
}

export async function getCheckPoints(uuid: string): Promise<CheckPointType[]> {
  const path = `/quality_controls/${uuid}/available-checkpoints`;

  const apiRes = await fetchData(path, 'v2');
  const data = getMemberData(apiRes?.data as IHydra);

  return data;
}

export async function getAuthInfo(uuid: string): Promise<any[]> {
  const path = `/quality_controls/${uuid}/available-checkpoints`;

  const apiRes = await fetchData(path, 'v2');
  const data = getMemberData(apiRes?.data as IHydra);

  return data;
}

export async function getBuckets(checkpointId: string): Promise<Bucket[]> {
  const apiRes = await fetchData(
    `/buckets?checkpoint_id=${checkpointId}`,
    'v1'
  );
  return getMemberData(apiRes.data);
}

export async function getDefectOptions(uuid: string): Promise<Defect> {
  const apiRes = await fetchData(`/quality_controls/${uuid}/defect_options`);
  return apiRes.data;
}

export async function getTypeDimension(
  productId: string
): Promise<Dimension[]> {
  const apiRes = await fetchData(
    `/type_dimensions?product_id=${productId}`,
    'v1'
  );
  return getMemberData(apiRes.data);
}

export async function uploadImage(
  uuid: string,
  file: File,
  type: UploadImageType,
  token?: string | null
): Promise<{ imageUrl: string; imagePath?: string }> {
  const pathName = type === 'defect' ? 'defect_images' : 'additional_images';

  const version = type === 'defect' ? 'v1' : 'v2';

  const bodyFormData = new FormData();

  bodyFormData.append('image', file);

  if (type === 'internal' || type === 'public') {
    bodyFormData.append('type', type);
  }

  const authHeader = token ? { Authorization: `Bearer ${token}` } : {};

  const apiRes = await postData(
    `/quality_controls/${uuid}/${pathName}`,
    version,
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...authHeader,
      },
    }
  );

  return apiRes.data;
}

export async function submitDefect(
  uuid: string,
  data: DefectRequest
): Promise<SubmittedDefect> {
  const apiRes = await postData(
    `/quality_controls/${uuid}/defect_records`,
    'v2',
    data
  );

  return apiRes.data;
}

export async function getAgentActivity(): Promise<AgentActivity> {
  const path = `/quality_controls/current-agent`;

  const apiRes = await fetchData(path, 'v2');
  return apiRes.data;
}

export async function getQcStates(): Promise<QcStatesType[]> {
  const apiRes = await fetchData(`/states`, 'v1');

  return getMemberData(apiRes.data);
}

export async function getQcList(
  params: QcListQueryParams
): Promise<QcListType> {
  const qcList = await fetchData(`/quality_controls`, 'v2', params);

  return {
    data: getMemberData(qcList.data),
    totalItems: getTotalItems(qcList.data),
  };
}

export async function getProductComments(
  uuid: string
): Promise<ProductComment[]> {
  const apiRes = await fetchData(
    `/quality_controls/${uuid}/product-comments`,
    'v2'
  );
  return getMemberData(apiRes.data);
}
